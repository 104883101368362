import React, { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import CMTLogo from "../assets/Images/Logo_Mosaic.svg";
// import { styled, Box } from '@mui/system';
import { Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import IconButton from "@mui/material/IconButton";
import ChangePassword from "./ChangePassword";
import { useSelector } from "react-redux";
import ROUTES from "../utils/routes";
import Cookies from "js-cookie";
import Avatar from "@mui/material/Avatar";
import { Logout } from "../assets/icons";
import DialogBox from "../../src/Components/common/dialog";
import "../assets/scss/navbar.scss";

export default function Navbar() {
  // Selectors
  const { userData } = useSelector((state) => state?.user);
  const { reportSidebarData } = useSelector((state) => state?.allReportsDetails);

  // After browser relaod it's making to false (handle with redux persist // Todo)
  // const { isLoggedIn } = useSelector((state) => state?.login);

  const isLoggedIn = Cookies.get("isLoggedIn", "");

  // Hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Local State
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLogoutConfirmationDialog, setIsLogoutConfirmationDialog] =
    useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    setIsLogoutConfirmationDialog(true);
  };

  const handleLogoutConfirmation = () => {
    setIsLogoutConfirmationDialog(false);
    navigate("/");
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("isLoggedIn");
  };

  const handleLogoutCancelled = () => {
    setIsLogoutConfirmationDialog(false);
  };

  const handleResetPassword = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const isActive = (path) => {
    return location.pathname.toLowerCase().startsWith(path.toLowerCase());
  };

  const reloadPage = () => window.location.reload();

  return isLoggedIn ? (
      <>
        <div className="navbar">
          <a
            href="/Bank-Txns/Upload-files"
            onClick={reloadPage}
            style={{ cursor: "pointer" }}
          >
            <img src={CMTLogo} alt="CMT LOGO" />
          </a>
          {!(
            location.pathname === "/" ||
            location.pathname === "/forget-password"
          ) && (
            <>
              <div className="navBarContainer">
                <NavLink
                  to={ROUTES.Dashboard}
                  className={`navLink ${
                    isActive("/Dashboard") ? "activeLinkStyle" : ""
                  }`}
                >
                  Dashboard
                </NavLink>
                <NavLink
                  to={ROUTES.MUUF}
                  className={`navLink ${
                    isActive("/Bank-Txns") ? "activeLinkStyle" : ""
                  }`}
                >
                  Bank Txns
                </NavLink>

                <NavLink
                  to="/cash-processing"
                  className={`navLink ${
                    location.pathname === "/cash-processing"
                      ? "activeLinkStyle"
                      : ""
                  }`}
                >
                  Reconciliation
                </NavLink>
                {/* <NavLink
                to="/bdx"
                className={`navLink ${
                  location.pathname === "/bdx" ? "activeLinkStyle" : ""
                }`}
              >
                BDX
              </NavLink> */}

                <NavLink
                  // to='/BDX'
                  to={ROUTES.ExceptionReport}
                  className={`navLink ${
                    isActive("/bdx") ? "activeLinkStyle" : ""
                  }`}
                >
                  BDX
                </NavLink>

                <NavLink
                  // to="/Payment-reconciliation"
                  to={ROUTES.TreasuryList}
                  className={`navLink ${
                    isActive("/Treasury") ? "activeLinkStyle" : ""
                  }`}
                >
                  Treasury
                </NavLink>
                <NavLink
                  to={reportSidebarData[0]?.path ?? ROUTES.CASHTRACKER}
                  className={`navLink ${
                    isActive("/Reports") ? "activeLinkStyle" : ""
                  }`}
                >
                  Reports
                </NavLink>
                <NavLink
                  to={ROUTES.AUM}
                  className={`navLink ${
                    isActive("/administration") ? "activeLinkStyle" : ""
                  }`}
                >
                  Administration
                </NavLink>
              </div>

              <div className="navBarRightContainer">
                <div className="curvedMenuButton" onClick={handleMenuClick}>
                  <Avatar className="userIcon">
                    {userData?.user_name?.charAt(0)}
                  </Avatar>
                  <Typography variant="body1" className="userName">
                    {userData?.user_name ?? ""}
                  </Typography>
                  <IconButton>
                    <ArrowDropDownIcon className="WhiteArrowDropDownIcon" />
                  </IconButton>
                </div>

                <div className="logoutButton" onClick={handleLogout}>
                  <IconButton>
                    <Logout />
                  </IconButton>
                </div>
              </div>
            </>
          )}
        </div>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleResetPassword}>Change Password</MenuItem>
        </Menu>

        {open && <ChangePassword setOpen={setOpen} />}

        {isLogoutConfirmationDialog && (
          <DialogBox
            title="Logout"
            body="Do you want to Logout?"
            confirmText="Logout"
            isOpen={isLogoutConfirmationDialog}
            onCancel={handleLogoutCancelled}
            onConfirm={handleLogoutConfirmation}
          />
        )}
      </>
  ) : (
    <div className="navbar">
      <a
        href="/Bank-Txns/Upload-files"
        onClick={reloadPage}
        style={{ cursor: "pointer" }}
      >
        <img src={CMTLogo} alt="CMT LOGO" />
      </a>
    </div>
  );
}
