import { FormLabel, Grid, TextField } from "@mui/material";


export const ViewAndEditEntity = ({
  
    handleInputChange,
    index,
    row,
    viewMode,
    validationStatus,
}) => {
    //inline style
    const InlineStyle = {
        EntityDivison: {
            width: "230px", height: "32", padding: "0px"
        },
    }
    return (
      <form>
        <Grid container justifyContent={"space-between"}>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}

            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Broker Name</FormLabel>
            <TextField
              value={row.broker_name}
              onChange={(e) =>
                handleInputChange(index, "broker_name", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.broker_name && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.broker_name}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Broker</FormLabel>
            <TextField
              value={row.broker}
              onChange={(e) =>
                handleInputChange(index, "broker", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.broker && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.broker}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Duplicate Count</FormLabel>
            <TextField
    value={row.duplicate_count}
    onChange={(e) => {
        const inputValue = e.target.value;
        // Check if input value is a valid number
        if (!isNaN(inputValue)) {
            handleInputChange(index, "duplicate_count", inputValue);
        }
    }}
    sx={InlineStyle.EntityDivison}
    size="small"
    placeholder="Enter Details Here"
    disabled={viewMode}
    inputProps={{
        inputMode: 'numeric', // Set input mode to numeric
        pattern: '[0-9]*' // Allow only numbers
    }}
/>
            <br />
            {validationStatus[index]?.duplicate_count && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.duplicate_count}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>SOA Received from broker</FormLabel>
            <TextField
              value={row.soa_received_from_broker}
              onChange={(e) =>
                handleInputChange(index, "soa_received_from_broker", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.soa_received_from_broker && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.soa_received_from_broker}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Name</FormLabel>
            <TextField
              value={row.name}
              onChange={(e) =>
                handleInputChange(index, "name", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.name && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.name}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>email</FormLabel>
            <TextField
              value={row.email}
              onChange={(e) =>
                handleInputChange(index, "email", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.email && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.email}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Phone Number</FormLabel>
            <TextField
              value={row.phone_number}
              onChange={(e) =>
                handleInputChange(index, "phone_number", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.phone_number && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.phone_number}
              </span>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            lg={3}
            display={"flex"}
            flexDirection={"column"}
            gap={"5px"}
            mt={"20px"}
          >
            <FormLabel>Broker Branch Location</FormLabel>
            <TextField
              value={row.broker_branch_location}
              onChange={(e) =>
                handleInputChange(index, "broker_branch_location", e.target.value)
              }
              sx={InlineStyle.EntityDivison}
              size="small"
              placeholder="Enter Details Here"
              disabled={viewMode}
            />
            <br />
            {validationStatus[index]?.broker_branch_location && (
              <span style={{ color: "red" }}>
                {validationStatus[index]?.broker_branch_location}
              </span>
            )}
          </Grid>
        </Grid>
      </form>
    );
}