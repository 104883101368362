import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Switch, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import "react-toastify/dist/ReactToastify.css";
import "./cash-allocation.scss";
import AllocationsTable from "./AllocationsTable";
import { useAllocationList, useBankDetails, useSearchParams } from "./hooks";
import instance from "../../redux/api";
import AddNewAllocation from "./AddNewAllocation";
import FileUpload from "./FileUpload";
import { CommonButton } from "../common/commonButton";
import { numberFormatter } from "../../utils/generalFunctions";
import { CustomLoader } from "../CustomLoader";
import DialogBox from "../common/dialog";
import { useCorrectiveTrf } from "./hooks";

export default function DataTable() {
  const cashAllocationDetailsClose = "action-button filled pill danger";

  const { userData } = useSelector((state) => state?.user);

  const transactionId = useSearchParams("txn_id");
  const [selectedRows, setSelectedRows] = useState([]);
  const [addingRecord, setAddingRecord] = useState(false);
  const [validationStatus, setValidationStatus] = useState([]);
  const [isEditingRecord, setIsEditingRecord] = useState(null);
  const { bankDetails, transactionDetails } = useBankDetails(transactionId);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const [isAPIInProgress, setIsAPIInProgress] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(true);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);

  const [triggerCTWorkflow, setTriggerCTWorkflow] = useState(false);
  const [currentAllocation, setCurrentAllocation] = useState(null);
  
  // hooks
  const {
    allocationList,
    setAllocationList,
    saveAllocationData,
    deleteAllocationData,
    totalAllocatedAmount,
    totalReceivableAmount,
    loadData,
    apiProgressBar,
  } = useAllocationList(transactionId);

  const handleDeleteClick = async (id) => {
    try {
      setIsDeleteInProgress(true);
      const response = await deleteAllocationData(id);
      
      // close delete confirm dialog
      setOpenDeleteConfirmDialog(false);

      // Check if the deletion was successful
      if (response.status === 204 || response.status === 200) {
        // If successful, show a success toast
        toast.success("Item deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // If needed, update the state to reflect the deletion
        const updatedAllocations = allocationList.filter(
          (row) => row.id !== id
        );
        setAllocationList([...updatedAllocations]);
        setSelectedRows([]);
        setSelectedRowIndex(-1);
        setIsDeleteInProgress(false);
        // Perform any additional actions here
      } else {
        // If deletion was not successful, show an error toast
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsDeleteInProgress(false);
      }
    } catch (error) {
      // If an error occurs during deletion, show an error toast
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsDeleteInProgress(false);
    }
  };

  const displayToastMessage = (message, type = "error") => {
    toast.error(message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  }

  const handleSubmit = async ({ policy, ...item }) => {
    try {
      // Bank roe should be 1 when Bank_Currency_Code and original_ccy is same
      if (Number(item?.bank_roe) <= 0 && item?.bank_txn?.Bank_Currency_Code === item?.original_ccy) {
        item.bank_roe = 1.000000;
      }

      if (Number(item?.bank_roe) <= 0) {
        displayToastMessage("Bank ROE cannot be Zero");
        return
      }

      // Trigger UI Notification when allocation_status is Allocated and Receivable, Allocated amounts are Zero
      if (item?.allocation_status === "Allocated") {
        if (Number(item?.receivable_amt) === 0) {
          displayToastMessage("Receivable amount cannot be Zero");
          return
        }
        if (Number(item?.allocated_amt) === 0) {
          displayToastMessage("Allocated amount cannot be Zero");
          return
        }
        if (parseFloat(item?.receivable_amt) != parseFloat(item?.allocated_amt)) {
          displayToastMessage("Remaining Balance should be Zero");
          return
        }
      }

      setCurrentAllocation(item);

      // Update audit fields
      const { audit_fields: auditFields } = item;
      auditFields.fields[0].value = item?.policy_id;
      auditFields.fields[1].value = item?.allocation_status;
      auditFields.fields[2].value = item?.receivable_amt;
      auditFields.fields[3].value = item?.allocated_amt;
      auditFields.user_id = userData?.id;

      const payload = {
        ...item,
        bank_txn: item?.bank_txn?.id ?? 0,
        policy_fk: item?.policy_fk?.id ?? null,
        policy_pk: item?.policy_pk ?? item?.policy_fk?.id,
        policy_mf: item?.policy_mf ?? [],
        audit_fields: auditFields,
      };

      setIsAPIInProgress(true);
      const response = await saveAllocationData(payload);
      setIsAPIInProgress(false);

      if (response.status === 200 || response.status === 201) {
        // Trigger Corrective transfer workflow when producing entity is different from bank details entity_number
        if (item?.policy_fk?.Producing_Entity !== item?.bank_txn?.bank_details?.entity_number) {
          // Do not trigger workflow if entity name and settlement currency are blank or null
          if(item?.policy_fk?.Settlement_Ccy && item?.policy_fk?.Producing_Entity) {
            setTriggerCTWorkflow(true);
          }
        } else {
          loadData();
        }

        toast.success("Item Updated successfully!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setSelectedRowIndex(-1);
      }
    } catch (err) {
      console.error("Error:", err);
      setIsAPIInProgress(false);
      toast.error(err?.data?.msg || "Unable to save cash allocation data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const startAddingNewRow = () => {
    setAddingRecord(true);
  };

  const handleAddRow = (data) => {
    toast.success("Successfully Added new allocation!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });

    if (data?.bank_txn) {
      loadData();
    } else {
      try {
        instance
          .get(
            `bankmanagement/get_transactions_by_txn_id/?txn_id=${data?.bank_txn}`
          )
          .then((response) => {
            let data = response.data;
            // disable the row then when the allocation status is "Allocated"
            data = data?.map((item) => {
              return {
                ...item,
                disabled: item?.allocation_status === "Allocated",
                audit_fields:{
                  user_id: userData?.id,
                  fields: [
                    {
                      field_name: "policy_id",
                      old_value: item?.policy_id,
                      value: item?.policy_id
                    },
                    {
                      field_name: "allocation_status",
                      old_value: item?.allocation_status,
                      value: item?.allocation_status
                    },
                    {
                      field_name: "receivable_amt",
                      old_value: item?.receivable_amt,
                      value: item?.receivable_amt
                    },
                    {
                      field_name: "allocated_amt",
                      old_value: item?.allocated_amt,
                      value: item?.allocated_amt
                    }
                  ],
                  txn_id: item?.audit_txn?.id,
                  audit_txn_name: "CASH_ALLOCATION"
                }
              }
            });
            setAllocationList([...data]);
          })
          .catch((error) => {
            console.error("Error fetching allocations:", error);
            toast.error("Unable fetch the added new allocation!", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          });
      } catch (err) {
        console.error("err", err);
      }
    }

    cancelAddRow();
  };

  const cancelAddRow = () => {
    setAddingRecord(false);
    setIsEditingRecord(null);
  };

  // Add state for modal visibility
  const [isPolicyErrorModalOpen, setIsPolicyErrorModalOpen] = useState(false);

  // Function to handle modal close
  const handleClosePolicyErrorModal = () => {
    setIsPolicyErrorModalOpen(false);
  };

  const handleDeleteRow = async () => {
    if (selectedRowIndex === -1) {
      toast.error(`Please select one row to delete`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    try {
      const response = await instance.get(`bankmanagement/cash_allocation_activities/${allocationList[selectedRowIndex].id}/`);
      if (response.status === 200 && response.data.message === "Success") {
        setOpenDeleteConfirmDialog(true);
      } else {
        setIsPolicyErrorModalOpen(true);
      }
    } catch (error) {
      toast.error('Error while getting details of the policy', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };


  const toggleTransactionDetails = useCallback(() => {
    setShowTransactionDetails((t) => !t);
  }, [setShowTransactionDetails]);

  const handleDeleteCancelled = () => {
    setOpenDeleteConfirmDialog(false);
  };

  const handleDeleteConfirmed = () => {
    const row = allocationList[selectedRowIndex];
    handleDeleteClick(row.id);
  };

  return (
    <>
      <div
        className={`cash-allocation-page ${
          showTransactionDetails ? "with-details" : "without-details"
        }`}
      >
        <div className="top-details-section">
          <div className="header">
            <div className="page-title-container">
              <span className="page-title">Cash Allocation Details</span>
            </div>
            <div className="page-actions" style={{ display: "flex" }}>
              <div>
                {" "}
                <div style={{ marginRight:"10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="cash-alloc-indicator historical"
                    style={{ marginRight: "10px" }} />{" "}
                    Historical
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="cash-alloc-indicator non-historical"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Non-Historical
                  </div>
                </div>
              </div>
              <CommonButton
                text="X Close Details"
                handleClick={() => window.history.back()}
                className={cashAllocationDetailsClose}
              />
            </div>
          </div>
          <div className="transaction-details">
            <div className="transaction-details-header">
              <span className="switch-container">
                <span className="page-title">View Bank Details</span>
                <Switch
                  inputProps={{ "aria-label": "Show Transaction Details" }}
                  onChange={toggleTransactionDetails}
                  checked={showTransactionDetails}
                />
                <span className="top-amount-details">
                  Total Receivable Amount:{" "}
                  <span>
                    {numberFormatter(transactionDetails.Receivable_Amount)}
                  </span>
                </span>
                <span className="top-amount-details">
                  Receivable Amount:{" "}
                  <span>
                    {numberFormatter(totalReceivableAmount)}
                  </span>
                </span>
                <span className="top-amount-details">
                  Total Allocated Amount:{" "}
                  <span>{numberFormatter(totalAllocatedAmount)}</span>
                </span>
                <span className="top-amount-details">
                  Variance:{" "}
                  <span>
                    {numberFormatter(transactionDetails.Receivable_Amount - totalReceivableAmount)}
                  </span>
                </span>
              </span>
              <FileUpload transaction={transactionDetails} />
            </div>

            {showTransactionDetails && (
              <div className="transaction-details-card">
                {bankDetails.map(({ label, value }) => (
                  <span
                    key={label}
                    className={`transaction-item ${
                      label === "Payment reference" ? "w-50" : ""
                    }`}
                  >
                    <span className="transaction-item-label">{label}</span>
                    <span className="transaction-item-value" title={value}>
                      : {value}
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="action-container">
            <h4 className="">Remittance List</h4>
            <div className="table-actions">
              <CommonButton
                text="Add"
                handleClick={() => startAddingNewRow()}
                sx={{ borderRadius: "30px" }}
                hoverColor="#FF5A01"
              />
              { allocationList?.length > 0 && (
                <CommonButton
                text="Delete"
                handleClick={() => handleDeleteRow()}
                sx={{ borderRadius: "30px", marginLeft: "20px" }}
                hoverColor="#FF5A01"
                disabled={userData?.role?.toLowerCase() !== "manager"}
              />
              )
              }
            </div>
          </div>
        </div>

        <div className="table-section">
          <AllocationsTable
            allocations={allocationList}
            setAllocations={setAllocationList}
            validationStatus={validationStatus}
            setValidationStatus={setValidationStatus}
            handleSave={handleSubmit}
            transaction={transactionDetails}
            selectedRowIndex={selectedRowIndex}
            setSelectedRowIndex={setSelectedRowIndex}
            apiProgressBar={apiProgressBar}
            setIsEditingRecord={setIsEditingRecord}
          />
        </div>

        {isAPIInProgress && <CustomLoader />}

        <ToastContainer />
        
        {(addingRecord || isEditingRecord) && (
          <AddNewAllocation
            onSuccess={handleAddRow}
            onCancel={cancelAddRow}
            isEditingRecord={isEditingRecord}
            transaction={transactionDetails}
          />
        )}

        <DialogBox
          title="Delete Confirmation"
          body={`Are you sure you want to delete this policy ${allocationList?.[selectedRowIndex]?.policy_id}?`}
          confirmText="Delete"
          isOpen={openDeleteConfirmDialog}
          onCancel={handleDeleteCancelled}
          onConfirm={handleDeleteConfirmed}
          isDisableConfirm={isDeleteInProgress}
        />

        {triggerCTWorkflow && (
          <TriggerCorrectiveTransferWorkflow
            currentAllocation={currentAllocation}
            transactionDetails={transactionDetails}
            loadData={loadData}
          />
        )}

      </div>
      {/* Add the Dialog component for the policy error modal */}
      <Dialog
        open={isPolicyErrorModalOpen}
        onClose={handleClosePolicyErrorModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Warning!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Policy cannot be updated as there are activities associated to it.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" sx={{ mt: 2 }}>
            Kindly check !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePolicyErrorModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}


const TriggerCorrectiveTransferWorkflow = ({ currentAllocation, transactionDetails, loadData }) => {
  const { formData, setFormData, submit } = useCorrectiveTrf(currentAllocation, transactionDetails);

  const triggerCTWorkflowAPI = async () => {
    setFormData({
      ...formData,
      bank_curr: currentAllocation?.policy_fk?.Settlement_Ccy ?? null,
    })
    await submit();
    loadData();
  }

  useEffect(() => {
    triggerCTWorkflowAPI();
  }, []);

  return (
    <>
    </>
  )
};