import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import "./manualPolicyCorrectionContainer.css";
import { useStyles } from "./manualPolicyCorrectionContainerStyles";
import { CommonPaginationContainer } from "../common";
import { getPaginationCount } from "../CashAllocation/utils";
import {
  Stack,
} from "@mui/material";
import { getManualPolicyReconciliation, getManualPolicyReconciliationReport, GetUsers } from "../../Services/api";
import instance from "../../redux/api";
import { toast } from "react-toastify";
import Radio from '@mui/material/Radio';
import { CommonButton } from "../common/commonButton";
import CircularProgress from '@mui/material/CircularProgress';
import DialogBox from "../common/dialog";


export const ManualPolicyCorrectionContainer = () => {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  // const { userData } = useSelector((state) => state?.user);

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedPolicyRow, setSelectedPolicyRow] = useState(null);
  const [loadingPolicyAge, setLoadingPolicyAge] = useState(false);
  // const [openSkipDialog, setOpenSkipDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [skipSuccess, setSkipSuccess] = useState(false);

  //Pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const columns = [
    {
      field: "select",
      headerName: "",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.3,
      renderCell: (params) => (
        <>
          {loadingPolicyAge === params.row.id ? (
            <CircularProgress size={20} />
          ) : (
            <Radio
              checked={selectedPolicyRow === params.row.id}
              onChange={() => {
                setSelectedPolicyRow(params.row.id);
                setSelectedRow(null);
                loadPolicyAgeData(params.row.policy_id, params.row.id);
                setPolicyID(params.row.id);
              }}
              value={params.row.id}
              name="radio-buttons"
              inputProps={{ 'aria-label': params.row.id }}
            />
          )}
        </>
      )
    },
    {
      field: "policy_id",
      headerName: "Policy ID",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.policy_id,
      renderCell: (params) => <>{params.row?.policy_id ?? ""}</>
      // renderCell: (params) => (
      //   loadingPolicyAge === params.row?.id ? <CircularProgress size={20} /> :
      //   <a
      //     href={`javascript:void(0)`}
      //     onClick={() => {
      //       loadPolicyAgeData(params.row?.policy_id, params.row?.id);
      //       setPolicyID(params?.row?.id);
      //     }}
      //     rel="noopener noreferrer"
      //     role="button"
      //     style={{ color: "blue" }}
      //   >
      //     {params.value}
      //   </a>
      // ),
    },
    {
      field: "allocation_scm",
      headerName: "Allocation SCM",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.allocation_scm,
      renderCell: (params) => <>{params.row?.allocation_scm ?? ""}</>
    },
    {
      field: "allocation_umr",
      headerName: "Allocation UMR",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.allocation_umr,
      renderCell: (params) => <>{params.row?.allocation_umr ?? ""}</>
    },
    {
      field: "allocation_binder",
      headerName: "Allocation Binder",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.allocation_binder,
      renderCell: (params) => <>{params.row?.allocation_binder ?? ""}</>
    },
    {
      field: "allocation_entity",
      headerName: "Allocation Entity",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.allocation_entity,
      renderCell: (params) => <>{params.row?.allocation_entity ?? ""}</>
    },
    {
      field: "installment_number",
      headerName: "Installment Number",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.7,
      valueGetter: (params) => params.row?.installment_number,
      renderCell: (params) => <>{params.row?.installment_number ?? ""}</>
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.7,
      valueGetter: (params) => params.row?.settlement_ccy?.currency_code,
      renderCell: (params) => <>{params.row?.settlement_ccy?.currency_code ?? ""}</>
    },
    {
      field: "original_ccy",
      headerName: "Original Currency",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.7,
      valueGetter: (params) => params.row?.original_ccy,
      renderCell: (params) => <>{params.row?.original_ccy ?? ""}</>
    },
    {
      field: "policy_original_amount",
      headerName: "Policy Original Amount",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.installment_amount_org,
      renderCell: (params) => <>{params.row?.installment_amount_org ?? ""}</>
    },
    {
      field: "installment_duedate",
      headerName: "Installment Due Date",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.7,
      valueGetter: (params) => params.row?.installment_duedate,
      renderCell: (params) => <>{params.row?.installment_duedate ?? ""}</>
    },
  ];

  if (localStorage.getItem("userRole") !== "CC Processor") {
    columns.push({
      field: "assign_to",
      headerName: "Assign To",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.7,
      valueGetter: (params) => params.row?.bank_txn?.Assigned_User,
      renderCell: (params) => <>{params.row?.bank_txn?.Assigned_User ?? ""}</>
    });
  }

  const ageColumns = [
    {
      field: "select",
      headerName: "",
      headerAlign: "center",
      minWidth: "5%",
      flex: 0.3,
      renderCell: (params) => (
        <Radio
          checked={selectedRow === params.row.id}
          onChange={() => {
            setSelectedRow(selectedRow === params.row.id ? null : params.row.id);
          }}
          value={params.row.id}
          name="radio-buttons"
          inputProps={{ 'aria-label': params.row.id }}
        />
      )
    },
    {
      field: "policy_id",
      headerName: "Policy ID",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Policy_Line_Ref,
      renderCell: (params) => <>{params.row?.Policy_Line_Ref ?? ""}</>
    },
    {
      field: "allocation_scm",
      headerName: "Allocation SCM",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.SCM_Partner,
      renderCell: (params) => <>{params.row?.SCM_Partner ?? ""}</>
    },
    {
      field: "allocation_umr",
      headerName: "Allocation UMR",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.UMR_Number,
      renderCell: (params) => <>{params.row?.UMR_Number ?? ""}</>
    },
    {
      field: "allocation_binder",
      headerName: "Allocation Binder",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Syndicate_Binder,
      renderCell: (params) => <>{params.row?.Syndicate_Binder ?? ""}</>
    },
    {
      field: "allocation_entity",
      headerName: "Allocation Entity",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Producing_Entity,
      renderCell: (params) => <>{params.row?.Producing_Entity ?? ""}</>
    },
    {
      field: "installment_number",
      headerName: "Installment Number",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Instalment_Nbr,
      renderCell: (params) => <>{params.row?.Instalment_Nbr ?? ""}</>
    },
    {
      field: "settlement_currency",
      headerName: "Settlement Currency",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Settlement_Ccy,
      renderCell: (params) => <>{params.row?.Settlement_Ccy ?? ""}</>
    },
    {
      field: "original_ccy",
      headerName: "Original Currency",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Original_Ccy,
      renderCell: (params) => <>{params.row?.Original_Ccy ?? ""}</>
    },
    {
      field: "policy_original_amount",
      headerName: "Policy Original Amount",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Installment_Agency_Amount_in_Orig,
      renderCell: (params) => <>{params.row?.Installment_Agency_Amount_in_Orig ?? ""}</>
    },
    {
      field: "installment_duedate",
      headerName: "Installment Due Date",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Installment_Due_date,
      renderCell: (params) => <>{params.row?.Installment_Due_date ?? ""}</>
    },
    {
      field: "Paid_Amount_in_USD",
      headerName: "Paid Amount In USD",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
      valueGetter: (params) => params.row?.Paid_Amount_in_USD,
      renderCell: (params) => <>{params.row?.Paid_Amount_in_USD ?? ""}</>
    },
  ];

  // const [users, setUsers] = useState([]);
  const [policyID, setPolicyID] = useState(null);
  const [policyAgeData, setPolicyAgeData] = useState([]);

  // useEffect(() => {
  //   // Fetch users data from an API
  //   instance
  //     .get(GetUsers)
  //     .then((data) => {
  //       setUsers(data.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching users:", error);
  //     });
  // }, []);

  const loadPolicyAgeData = async (policyId, rowId) => {
    setLoadingPolicyAge(rowId);
    try {
      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;
      const response = await instance.get(`${getManualPolicyReconciliationReport}${policyId}/?${queryParams}`);
      if (response?.data?.data?.length > 0) {
        setPolicyAgeData(response?.data?.data);
      } else {
        setPolicyAgeData([]);
        toast.error("No record found in the age table", {
          position: toast.POSITION.TOP_RIGHT,

          autoClose: 1000,
        });
      }
    } catch (err) {
      console.error("err", err);
    } finally {
      setLoadingPolicyAge(null);
    }
  };

  const loadData = async () => {
    setUpdateSuccess(false);
    setSkipSuccess(false);
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }))

      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}`;
      const response = await instance.get(`${getManualPolicyReconciliation}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }))

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }))
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const handleUpdate = async (type) => {
    const selectedRowData = policyAgeData.find(row => row.id === selectedRow);
    const payload = {
      type: type,
      policyID: policyID,
      SCM_Partner: selectedRowData?.SCM_Partner,
      UMR_Number: selectedRowData?.UMR_Number,
      Syndicate_Binder: selectedRowData?.Syndicate_Binder,
      Producing_Entity: selectedRowData?.Producing_Entity,
      Instalment_Nbr: selectedRowData?.Instalment_Nbr,
      Settlement_Ccy: selectedRowData?.Settlement_Ccy,
      Original_Ccy: selectedRowData?.Original_Ccy,
      Installment_Agency_Amount_in_Orig: selectedRowData?.Installment_Agency_Amount_in_Orig,
      Installment_Due_date: selectedRowData?.Installment_Due_date,
      Paid_Amount_in_USD: selectedRowData?.Paid_Amount_in_USD,
    };
    const response = await instance.patch(`${getManualPolicyReconciliation}${selectedRow}/`, payload);

    if (response?.status === 200) {
      toast.success(response?.data?.message || "Data updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      toast.error(response?.data?.message || "Failed to update data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    setOpenUpdateDialog(false);
    // setOpenSkipDialog(false);
    setUpdateSuccess(true);
    setSkipSuccess(true);
    setPolicyAgeData([]);
  }

  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.pageSize, pageState?.total]);

  useEffect(() => {
    loadData();
  }, [pageState?.pageSize, pageState?.page, updateSuccess, skipSuccess]);

  return (
    <div style={Container}>
      <div className="assignBankStatementBar">
        <span style={{ color: "#FF5A01", fontSize: "20px" }}>
          Manual Policy Reconciliation
        </span>
      </div>
      <div
        style={{
          height: policyAgeData.length > 0 ? 'calc(100vh - 350px)' : 'calc(100vh - 230px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          columns={columns}
          // checkboxSelection
          // disableRowSelectionOnClick
          // keepNonExistentRowsSelected
          startIcon={<AddIcon />}
          getRowId={(row) => row?.id}
          pagination
          paginationMode="server"
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen ? "173.137px !important" : "202px !important"
            },
            "& .MuiDataGrid-row": {
              minHeight: '52px !important',
              maxHeight: '52px !important'
            }
          }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Policies Found
              </Stack>
            ),
          }}
          componentsProps={{
            row: {
              style: {
                borderBottom: 'unset',
              },
            },
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      {policyAgeData.length > 0 &&
        <>
          <div className="assignBankStatementBar">
            <span style={{ color: "#FF5A01", fontSize: "20px" }}>
              Age Table
            </span>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <CommonButton
                text="Skip"
                className="bankStatementBtn"
                handleClick={() => setOpenSkipDialog(true)}
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || !selectedRow}
              /> */}
              <CommonButton
                text="Update"
                className="bankStatementBtn"
                handleClick={() => setOpenUpdateDialog(true)}
                hoverColor="#EE3F00"
                disabled={pageState?.isLoading || !selectedRow}
              />
            </div>
          </div>
          <div
            style={{
              height: 'calc(100vh - 293px)',
              position: "relative",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <DataGrid
              className={classes.pagination}
              loading={pageState?.isLoading}
              rows={policyAgeData ?? []}
              rowCount={policyAgeData?.length}
              columns={ageColumns}
              checkboxSelection={false}
              disableMultipleSelection
              selectionModel={policyAgeData.length > 0 ? [policyAgeData[0].id] : []}
              disableRowSelectionOnClick
              keepNonExistentRowsSelected
              startIcon={<AddIcon />}
              getRowId={(row) => row?.id}
              pagination
              paginationMode="server"
              disableSelectionOnClick
              page={pageState.page - 1}
              pageSize={pageState?.pageSize ?? 25}
              onPageSizeChange={(newPageSize) => {
                return setPageState((old) => {
                  setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
                  return { ...old, pageSize: newPageSize, page: 1 };
                });
              }}
              rowsPerPageOptions={[10, 25, 50, 100]}
              sx={{
                "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
                  minWidth: toggle?.isOpen ? "173.137px !important" : "202px !important"
                }
              }}
              components={{
                Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    No Policies Found
                  </Stack>
                ),
              }}
            />
            <Stack
              style={{
                position: "absolute",
                bottom: "8px",
              }}
            >
              <CommonPaginationContainer
                count={paginationCount + 1}
                pageState={pageState}
                setPageState={setPageState}
              />
            </Stack>
          </div>
        </>
      }
      {/* <DialogBox
        title="Skip Confirmation"
        body="Are you sure you want to skip?"
        confirmText="Yes"
        cancelText="No"
        isOpen={openSkipDialog}
        onCancel={() => setOpenSkipDialog(false)}
        onConfirm={() => handleUpdate('Skip')}
      /> */}
      <DialogBox
        title="Update Confirmation"
        body="Are you sure you want to update?"
        confirmText="Update"
        cancelText="Cancel"
        isOpen={openUpdateDialog}
        // onCancel={() => setOpenSkipDialog(true) }
        onCancel={() => setOpenUpdateDialog(false) }
        onConfirm={() => handleUpdate('Update')}
      />

    </div >
  );
};