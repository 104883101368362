import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  FormControl,
  Stack,
  Tooltip,
  FormLabel,
  Grid,
  Typography,
  TableContainer,
  TextareaAutosize,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import format from "date-fns/format";
import CustomDateRangePicker from "../common/CustomDateRangePicker";
import {
  BankTransaction,
  GetUsers,
  MutlipleAssignTxnUser,
  getBrokerBranchDetailsByBrokerBranchName,
} from "../../Services/api";
import instance from "../../redux/api";
import { BANK_STATEMENT_ROW_DATA } from "../../utils/config";
import DialogBox from "../common/dialog";
import "./bankTransaction.css";
import { CommonPaginationContainer, CommonSelect } from "../common";
import CommonFileUpload from "../documents/CommonFileUpload";
import { ViewAndEditBankTransaction } from "./ViewAndEditTxnDialog";
import DownloadIcon from "@mui/icons-material/DownloadOutlined";
import { AddBnkTransactionDetails } from "./AddBnkTransactionDetails";
import { CommonButton } from "../common/commonButton";
import {
  getAccountsList,
  getBrokerBranchesList,
  getCurrencyList,
} from "../../redux/slice/bankStatmentTransactionsSlice";
import { useStyles } from "./bankTranscationContainerStyles";
import { numberFormatter } from "../../utils/generalFunctions";
import { getPaginationCount } from "../CashAllocation/utils";
import { display } from "@mui/system";

const dialogStyle = {
  maxWidth: "90vw",
  width: "90vw",
  margin: "auto",
};

export default function BankTransactionsConatiner() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  const accountsList = useSelector(
    (state) => state?.bankStatmentTransactions?.accountsList
  );
  const brokerBranches = useSelector(
    (state) => state?.bankStatmentTransactions?.brokerBranchesList
  );
  const dropdownValuesCurreny = useSelector(
    (state) => state?.bankStatmentTransactions?.currencyList
  );

  const dispatch = useDispatch();

  // Local State
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpenAddBankTxnDialog, setIsOpenAddBankTxnDialog] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isCreateNewTransaction, setIsCreateNewTransaction] = useState(true);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [isDeletebankTxnProgress, setIsDeletebankTxnProgress] = useState(false)

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInputValue, setDialogInputValue] = useState("");
  const [currentPaymentReferenceIndex, setCurrentPaymentReferenceIndex] =
    useState(0);

  const [selectionModel, setSelectionModel] = useState([]);

  const [open, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const [rows, setRows] = useState([
    { ...BANK_STATEMENT_ROW_DATA, Receiving_Bank_Account: selectedOption },
  ]);
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );
  const [currentUpdatedFiledsInEditMode, setCurrentUpdatedFiledsInEditMode] =
    useState({});

  // File Upload
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  //bank statement transaction search
  const [fromDateValue, setFromDateValue] = useState("");
  const [toDateValue, setToDateValue] = useState("");
  const [searchFieldsPrams, setSearchFieldsPrams] = useState({
    Bank_Name: "",
    Receiving_Bank_Account: "",
    Bank_Transaction_Id: "",
  });

  const [currentRow, setCurrentRow] = useState("");
  const [isOpenTxnDialog, setIsOpenTxnDialog] = useState(false);
  const [txnMode, setTxnMode] = useState("VIEW");

  //Pagination
  const [paginationCount, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  const [isSearchPerformed, setIsSearchPerformed] = useState(false);
  const [isClearPerformed, setIsClearPerformed] = useState(false);
  const [isAccountDeSelected, setIsAccountDeSelected] = useState(false);
  const [isAssignToTxnApiProgress, setIsAssignToTxnApiProgress] =
    useState(false);

  const location = useLocation();

  const [tabValue, setTabValue] = useState(1);
  const [allocationType, setAllocationType] = useState("Unallocated");
  const [isBankNameSelected, setIsBankNameSelected] = useState(false);

  // Add a new state variable at the beginning of your component to track if the submit has been initiated
  const [isSubmitting, setIsSubmitting] = useState(false);

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  //styles
  const classes = useStyles();

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };


  useEffect(() => {
    setPaginationCount(getPaginationCount(pageState?.total, pageState?.pageSize));
  }, [pageState?.pageSize, pageState?.total]);

  const handleTabChange = async (_, newValue) => {
    setTabValue(newValue);
    let selectedOption = !newValue ? "Allocated" : "Unallocated";
    setAllocationType(selectedOption)
    setPageState((old) => ({
      ...old,
      page: 1,
      pageSize: 25,
    }));
  };

  const handleBankTransactionIdchange = (name, value) => {
    if (!value) {
      handleClearSearchList()
    }
    setSearchFieldsPrams({
      ...searchFieldsPrams,
      [name]: value?.trim(),
    });
  };

  const handleBankTnxSelectAccountDropdown = (name, value, reason) => {
    if (reason === "clear") {
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        [name]: "",
      });
      setIsAccountDeSelected(true);
    } else {
      setIsAccountDeSelected(false);
      setSearchFieldsPrams({
        ...searchFieldsPrams,
        [name]: value,
      });
    }
  };

  const searchQueryParams = () => {
    let getSearchQueryParams = "";

    const { Receiving_Bank_Account, Bank_Transaction_Id, Bank_Name } = searchFieldsPrams;

    if (Bank_Name) {
      getSearchQueryParams = `${getSearchQueryParams}&bankName=${Bank_Name}`;
    }

    if (Receiving_Bank_Account) {
      getSearchQueryParams = `${getSearchQueryParams}&accountNo=${Receiving_Bank_Account}`;
    }

    if (Bank_Transaction_Id) {
      getSearchQueryParams = `${getSearchQueryParams}&transactionId=${Bank_Transaction_Id}`;
    }

    let formattedFromDateValue = "",
      formattedToDateValue = "";

    if (fromDateValue && toDateValue) {
      formattedFromDateValue = format(fromDateValue, "dd/MM/yyyy");
      formattedToDateValue = format(toDateValue, "dd/MM/yyyy");
      getSearchQueryParams = `${getSearchQueryParams}&fromDate=${formattedFromDateValue}&toDate=${formattedToDateValue}`;
    }

    return getSearchQueryParams;
  }

  const handleSearchBankTransactionList = async () => {
    try {

      let queryParams = `?skip=0&pageSize=${pageState?.pageSize}`;

      const getSearchQueryParam = searchQueryParams();
      if (getSearchQueryParam) {
        queryParams = `${queryParams}${getSearchQueryParam}`;
      }

      if (location?.state?.fromDateValue && location?.state?.toDateValue && !queryParams.includes("fromDate")) {
        let formattedFromDateValue = format(location?.state?.fromDateValue, "dd/MM/yyyy");
        let formattedToDateValue = format(location?.state?.toDateValue, "dd/MM/yyyy");
        queryParams = `${queryParams}&fromDate=${formattedFromDateValue}&toDate=${formattedToDateValue}`;
      }

      let assigned = location?.state?.assigned || "";
      if (assigned) {
        queryParams = `${queryParams}&assigned=${assigned}`;
      }

      // set loading indicator
      setPageState((old) => ({
        ...old,
        isLoading: true
      }));

      const response = await instance.get(`${BankTransaction}${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
          page: 1,
        }));
        setIsSearchPerformed(true);
        setIsClearPerformed(false);
        // clear previous row selection
        handleSelectionModelChange([]);
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (error) {
      setPageState((old) => ({
        ...old,
        isLoading: false
      }));
      setPaginationCount(0);
      console.log("error", error);
    }
  };

  const handleClearSearchList = async () => {
    setIsClearPerformed(true);
    // clear fields
    setSearchFieldsPrams({
      Bank_Name: "",
      Receiving_Bank_Account: "",
      Bank_Transaction_Id: "",
    });
    getBankAccountsList("");
    setFromDateValue("");
    setIsSearchPerformed(false);
  };

  const bankTransactionPermisson =
    userData?.user_permissions?.permissions_list["Bank Transactions"] ?? {};

  useEffect(() => {
    // Fetch users data from an API
    instance
      .get(GetUsers)
      .then((data) => {
        const filteredUsers = data.data.filter(user => user.role === 'CC Processor' || user.role === 'Lead Role');
        setUsers([...new Set(filteredUsers)]);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleSubmitAssing = async () => {
    const assigningToInfo = users?.filter(
      (item) => item?.user_name === selectedUser
    );
    const assigningToId = assigningToInfo[0]?.id;

    const selectedRowsData =
      pageState?.data?.filter((row) => selectionModel.includes(row.id)) ?? [];

    try {
      setIsAssignToTxnApiProgress(true)
      const response = await instance.post(MutlipleAssignTxnUser, {
        user_login_id: assigningToId,
        Bank_Transaction_Ids: selectedRowsData?.map((item) => ({
          id: item.id,
        })),
      });
      if (response?.status === 200) {
        setIsAssignToTxnApiProgress(false);
        handleCloseDialog();
        handleSelectionModelChange([]);
        toast.success("Assign successful!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        loadData();
      } else {
        setIsAssignToTxnApiProgress(false)
        toast.error("Something Went Wrong", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (err) {
      console.log("err", err);
      setIsAssignToTxnApiProgress(false);
      toast.error("Something Went Wrong", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleAssignButtonClick = (params) => {
    setSelectedUser("");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Function to handle individual checkbox change
  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;

    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  // Function to handle "Select All" checkbox change
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  useEffect(() => {
    // Fetch Banks List
    dispatch(getAccountsList());
    // Fetch Broker branches
    dispatch(getBrokerBranchesList());
    // Fetch Currency Details
    dispatch(getCurrencyList());
    // Load data initially and on specific dependency changes
    loadData();
  }, [dispatch, allocationType, pageState?.pageSize, pageState?.page, isAccountDeSelected, isClearPerformed, isSearchPerformed]);

  const handleBankAccountDropdownChange = (value, reason = "") => {
    if (reason === "clear") {
      setSelectedOption(value);
      setResponseData({});
    } else {
      setSelectedOption(value);
    }
  };

  // Add Mode
  useEffect(() => {
    if (selectedOption) {
      if (selectedOption === "Select Account") {
        setResponseData({});
      } else {
        const getInfo = accountsList?.filter(
          (item) => item.account_number === selectedOption
        );
        setResponseData(getInfo[0]);
      }
    }
  }, [accountsList, selectedOption]);

  const handleActionButton = (currentRowData, currentTxnMode) => {
    setCurrentRow(currentRowData);
    setTxnMode(currentTxnMode);
    setIsOpenTxnDialog(true);
  };

  const handleAddTxnButton = () => {
    // Create an array of empty objects with the same structure as your rows
    const emptyRows = Array.from({ length: rows.length }, () => ({
      ...BANK_STATEMENT_ROW_DATA,
      Receiving_Bank_Account: selectedOption,
    }));

    setRows(emptyRows);
    setIsCreateNewTransaction(true);
    setSelectedOption("");

    setIsOpenAddBankTxnDialog(true);
  };

  const handleReset = () => {
    const emptyRows = Array.from({ length: rows.length }, () => ({
      ...BANK_STATEMENT_ROW_DATA,
      Receiving_Bank_Account: selectedOption,
    }));
    setRows(emptyRows);

    setResponseData({});
    setOpenResetDialog(false);
    handleBankAccountDropdownChange("");
    setUploadedFiles([]);
  };

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const handleErrorDialogClose = () => {
    setOpenErrorDialog(false);
  };

  const handleDeleteClick = async (id, transaction_id) => {
    const response = await instance.get(`bankmanagement/get_transactions_by_txn_id_validation/?txn_id=${transaction_id}`)
    if (response.status === 200 && response?.data?.message === "Not Success") {
      setIdToDelete(id);
      setOpen(true);
    } else {
      setOpenErrorDialog(true);
    }
  };

  const handleDeleteConfirmed = async () => {
    try {
      setIsDeletebankTxnProgress(true);
      // Send DELETE request to your API
      const response = await instance.delete(BankTransaction + idToDelete);

      // Check if the deletion was successful
      if (response.status === 200) {
        setIsDeletebankTxnProgress(false);
        // If successful, show a success toast
        toast.success("Transaction deleted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setOpen(false);
        setIdToDelete(null);

        loadData();
        // If needed, update the state to reflect the deletion
        const updatedRows = rows.filter((row) => row.id !== idToDelete);
        setRows(updatedRows);
      } else {
        setIsDeletebankTxnProgress(false)
        // If deletion was not successful, show an error toast
        toast.error(`Error deleting item: ${response.statusText}`, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      setIsDeletebankTxnProgress(false)
      // If an error occurs during deletion, show an error toast
      toast.error(`Error deleting item: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleDeleteCancelled = () => {
    setOpen(false);
    setIdToDelete(null);
  };

  const columns = [
    {
      field: "Bank_Transaction_Id",
      headerName: "Transaction ID",
      headerAlign: "center",
      minWidth: "5%",
      flex: 1,
    },
    {
      field: "Payment_Receive_Date",
      headerName: "Payment Rcv Date",
      headerClassName: "split-header",
      flex: 0.6,
      headerAlign: "center",
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        // Format the date using date-fns or any other date formatting library
        return format(new Date(params.value), "dd-MM-yyyy");
      },
    },
    {
      field: "Bank_Account_Name_Entity",
      headerName: "Bank Name",
      headerAlign: "center",
      flex: 0.8,
      valueGetter: (params) => params.row?.bank_details?.bank_name,
      renderCell: (params) => <>{params.row?.bank_details?.bank_name ?? ""}</>
    },
    {
      field: "Broker_Branch",
      headerName: "Broker Branch",
      headerAlign: "center",
      minWidth: "25%",
      flex: 1,
    },
    {
      field: "Assigned_User",
      headerName: "Assign To",
      headerAlign: "center",
      minWidth: "10%",
      flex: 0.6,
      valueGetter: (params) => params?.row?.Assigned_User?.user_name,
      renderCell: (params) => (
        <>
          {
            params?.row?.Assigned_User?.user_name ?? ""
          }
        </>
      ),
      filterable: true, // Enable filtering for this column
    },
    {
      field: "assigned_date",
      headerName: "Assigned Date",
      headerAlign: "center",
      minWidth: "25%",
      flex: 0.8,
      valueGetter: (params) => params?.row?.assigned_date,
      renderCell: (params) => {
        return params.row?.assigned_date ? format(new Date(params.row?.assigned_date), "dd-MM-yyyy") : "";
      }
    },
    // CMT-28
    {
      field: "transaction_status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: "25%",
      flex: 0.8,
      valueGetter: (params) => params?.row?.transaction_status,
      renderCell: (params) => (
        <>
          {params?.row?.transaction_status ?? ""}
        </>
      ),
    },
    {
      field: "Receivable_Amount",
      headerName: "Rcvd Settlement Amt",
      headerAlign: "center",
      headerClassName: "split-header",
      minWidth: "10%",
      flex: 0.8,
      valueGetter: (params) => params?.value,
      valueFormatter: (params) => {
        return numberFormatter(params?.value ?? 0);
      },
      cellClassName: "amount-table-cell"
    },
    {
      field: "Allocated_Amount",
      headerName: "Allocated Amount",
      headerAlign: "center",
      headerClassName: "split-header",
      minWidth: "10%",
      flex: 0.8,
      valueGetter: (params) => params?.row?.total_allocated,
      renderCell: (params) => (
        <>
          {params?.row?.total_allocated ?? ""}
        </>
      ),
      cellClassName: "amount-table-cell"
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      description: "This column has a value getter and is not sortable.",
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];

        if (bankTransactionPermisson?.["Edit"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  {" "}
                  <EditIcon />{" "}
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleActionButton(row, "EDIT")}
              color="inherit"
            />
          );
        }

        if (bankTransactionPermisson?.["Delete"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />{" "}
                </Tooltip>
              }
              label="Delete"
              onClick={() => handleDeleteClick(row.id, row.Bank_Transaction_Id)}
              color="inherit"
            />
          );
        }

        if (bankTransactionPermisson?.["View"] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, "VIEW")}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    },
    {
      field: "File_Name",
      headerName: "Review Bk Stmt/Preview",
      headerClassName: "split-header",
      headerAlign: "center",
      align: "right",
      sortable: false,
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      renderCell: (params) => (
        <a
          href={params?.row?.document_files[0]?.document_url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "blue" }}
          title={params?.row?.bank_reconciliation?.file_name}
        >
          {params?.row?.File_Name && <DownloadIcon />}
        </a>
      ),
    },
  ];

  const handleDialogClose = () => {
    setResponseData({});
    setIsOpenAddBankTxnDialog(false);
    setEditMode(false);
    setViewMode(false);
    setValidationStatus(Array(rows.length).fill(true));
    setUploadedFiles([])

    // reset Rows To Initial State
    resetRowsToInitialState();
  };

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }))

      // 
      let assigned = location?.state?.assigned || "";
      let queryParams = `skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize}&allocated=${allocationType}`;

      if (assigned) {
        queryParams = `${queryParams}&assigned=${assigned}`;
      }

      if (isSearchPerformed) {
        const getSearchQueryParam = searchQueryParams();
        if (getSearchQueryParam) {
          queryParams = `${queryParams}${getSearchQueryParam}`;
        }
      }

      let formattedFromDateValue = "";
      let formattedToDateValue = "";

      if (location?.state?.fromDateValue && location?.state?.toDateValue) {
        formattedFromDateValue = format(location?.state?.fromDateValue, "dd/MM/yyyy");
        formattedToDateValue = format(location?.state?.toDateValue, "dd/MM/yyyy");
        queryParams = `${queryParams}&fromDate=${formattedFromDateValue}&toDate=${formattedToDateValue}`;
      }

      const response = await instance.get(`${BankTransaction}?${queryParams}`);

      setPageState((old) => ({
        ...old,
        isLoading: false,
      }))

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data?.data ?? [],
        }));
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }))
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  // Handler for Bank Name Dropdown
  const handleBankNameDropdownChange = (name, value, reason) => {
    console.log("name, value, reason", name, value, reason)
    if (reason === "clear") {
      setIsBankNameSelected(false);
      setSearchFieldsPrams(prev => ({ ...prev, [name]: value, Receiving_Bank_Account: null })); // Also reset Account No when Bank Name is cleared
      getBankAccountsList("")
    } else {
      setIsBankNameSelected(true);
      setSearchFieldsPrams(prev => ({ ...prev, [name]: value }));
      getBankAccountsList(value)
    }
  };

  const [banksList, setBanksList] = useState([])
  const [selectedBankAccount, setSelectedBankAccount] = useState("");

  const getBanksList = async () => {
    const response = await instance.get("documents/get_all_unique_bank_names/")
    setBanksList(response?.data?.bank_names)
  }

  const getBankAccountsList = async (value) => {
    const response = await instance.get(`documents/get_all_unique_account_numbers_by_bank_name/?bank_name=${value}`)
    setSelectedBankAccount(response?.data?.account_numbers)
  }

  useEffect(() => {
    getBanksList()
    getBankAccountsList("")
  }, [])

  const handleSubmit = async () => {
    setIsSubmitting(true); // Disable the button when submit starts
    const hasFutureDate = rows.some((item) => {
      const paymentReceiveDate = new Date(item.Payment_Receive_Date);
      const today = new Date();
      return paymentReceiveDate > today;
    });

    if (hasFutureDate) {
      toast.error("Payment_Receive_Date cannot be in the future.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return; // Exit the function if there is a future date
    }

    // Add Mode
    rows.forEach((item) => {
      item["Allocation_Status"] = "Open";
      item["Accounting_Month"] = format(new Date(), "yyyy-MM-dd");
      // Specific Data
      item["Receiving_Bank_Account"] = selectedOption;
      item["bank_details"] = responseData?.id;
      item["Bank_Account_Name_Entity"] = responseData?.bank_name;
      item["PT_Receving_Bank_Name"] = responseData?.entity_name;
      item["Bank_Currency_Code"] = responseData?.currency;
    });

    // Validate each row before submission
    const areRowsValid = rows.every((_, index) => validateRow(index));

    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fill all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      let response = await instance.post(BankTransaction, rows);
      if (response) {
        if (uploadedFiles?.length) {
          const responseData = response?.data;
          let transactionsIds = [];
          responseData?.forEach((elemet) => {
            transactionsIds.push({ id: elemet.id });
          });

          const form = new FormData();
          var transactionsIdsString = JSON.stringify(transactionsIds);
          form.append("transaction_ids", transactionsIdsString);
          form.append("File_Name", uploadedFiles[0].file ?? "");

          return instance
            .post("bankmanagement/file_upload_to_transactions/", form, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              if (response?.data[0]?.["File_Name"]) {
                toast.success("Submission successful !", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
                cleanupTransactionData();
                // reset Rows To Initial State
                resetRowsToInitialState();
              } else {
                toast.error("Transaction created but File Upload got fail", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
                // reset Rows To Initial State
                resetRowsToInitialState();
              }
            })
            .catch((error) => {
              toast.error("Transaction created but File Upload got fail", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              console.error("Error fetching dropdown values:", error)
            });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }

      // clean up
      cleanupTransactionData();
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    setIsSubmitting(false); // Re-enable the button after submit is complete
  };

  // clean up data and close add or edit transaction dialog
  const cleanupTransactionData = () => {
    handleDialogClose();
    setResponseData({});
    loadData();
  };

  const errorMessages = { Bank_Exchange_Rate: "Exchange Rate cannot be negative.", Bank_Exchange_Charges: "Exchange Charges cannot be negative.", Bank_Charges: "Bank Charges cannot be negative." }

  const handleInputChange = (index, colName, value) => {
    if (["Bank_Exchange_Rate", "Bank_Exchange_Charges", "Bank_Charges",].includes(colName) && value < 0) {
      toast.error(errorMessages[colName], {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      setRows(updatedRows);

      // Edit Mode
      if (editMode) {
        setCurrentUpdatedFiledsInEditMode({
          ...currentUpdatedFiledsInEditMode,
          [colName]: value,
        });
      }
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };

  const handleBrokerBranchDropdown = (index, colName, value, reason, event) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      updatedRows[index][colName] = value;
      // get broker_information (Add Mode)
      instance
        .post(getBrokerBranchDetailsByBrokerBranchName, {
          Broker_Branch_Name: value,
        })
        .then((response) => {
          updatedRows[index]["broker_information"] = response?.data?.id;
          setRows(JSON.parse(JSON.stringify(updatedRows)));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };

  const handlePaymentReferenceOpenDialog = (
    curindex,
    currentRowPaymentRefData
  ) => {
    setOpenDialog(true);
    setDialogInputValue(currentRowPaymentRefData);
    setCurrentPaymentReferenceIndex(curindex);
  };

  const handleCloseDialogs = () => {
    setOpenDialog(false);
  };

  const handleSavePaymentReference = () => {
    setOpenDialog(false);
    handleInputChange(
      currentPaymentReferenceIndex,
      "Payment_Reference",
      dialogInputValue
    ); // Pass the index parameter
  };

  const handleDialogInputChange = (e) => {
    setDialogInputValue(e.target.value);
  };

  const handleBlur = (index, colName, value) => {
    // If the value is float
    if (!Number.isInteger(value)) {
      switch (colName) {
        case "Bank_Charges":
        case "Bank_Exchange_Charges":
        case "Receivable_Amount":
          value = parseFloat(value).toFixed(2);
          break;
        case "Bank_Exchange_Rate":
          value = parseFloat(value).toFixed(6);
          break;
        default:
      }
    }

    const updatedRows = [...rows];
    updatedRows[index][colName] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    const status = validateRow(rows.length - 1);
    if (status) {
      setRows([
        ...rows,
        { ...BANK_STATEMENT_ROW_DATA, Receiving_Bank_Account: selectedOption },
      ]);
      setDialogInputValue("");
    }
  };

  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here, for example:
    if (
      !row ||
      !row.Payment_Receive_Date ||
      !row.Payment_Receive_Date.trim() === ""
    ) {
      fieldValidations.Payment_Receive_Date = "This field is required";
    }

    if (
      !row ||
      !row.Payment_Reference ||
      !row.Payment_Reference.trim() === ""
    ) {
      fieldValidations.Payment_Reference = "This field is required";
    }

    // Update the logical OR conditions
    if (
      !row ||
      !row.Payment_Currency_Code ||
      !row.Payment_Currency_Code.trim() === ""
    ) {
      fieldValidations.Payment_Currency_Code = "This field is required";
    }

    if (
      !row ||
      !row.Receivable_Amount ||
      !row.Receivable_Amount.trim() === ""
    ) {
      fieldValidations.Receivable_Amount = "This field is required";
    }

    if (
      !row ||
      !row.Broker_Branch ||
      !row.Broker_Branch.trim() === ""
    ) {
      fieldValidations.Broker_Branch = "This field is required";
    }

    // check if account number is selected
    if (
      !selectedOption
    ) {
      fieldValidations.account_number = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };

  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows([]);
    setSelectAll(false);
  };

  const resetRowsToInitialState = () => {
    setRows([
      { ...BANK_STATEMENT_ROW_DATA, Receiving_Bank_Account: selectedOption },
    ]);
  };

  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);

    if (newSelectionModel.length === 0) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  const handleFileUploadDialog = () => {
    setIsFileUploadDialogOpen(true);
  };

  const handleReloadAccountsData = () => {
    loadData();
  };

  const isFilterBtnDisable = () => {
    let disabled = true;
    const { Receiving_Bank_Account, Bank_Transaction_Id, Bank_Name } = searchFieldsPrams;
    if (Receiving_Bank_Account !== "" || Bank_Transaction_Id !== "" || Bank_Name !== "" || fromDateValue !== "" || toDateValue !== "") {
      disabled = false;
    }
    return disabled;
  }

  // useEffect(() => {
  //   loadData();
  // }, [allocationType, pageState?.pageSize, pageState?.page]);  

  // // UseEffect
  // useEffect(() => {
  //   if (isAccountDeSelected || isClearPerformed) {
  //     loadData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAccountDeSelected, isClearPerformed]);

  // useEffect(() => {
  //   if (!isAccountDeSelected) {
  //     loadData();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageState?.pageSize, pageState?.page]);


  // combined useEffect above 3 useEffect
  useEffect(() => {
    // This will check if the conditions are met to call loadData only once
    if ((isAccountDeSelected || isClearPerformed) || !isAccountDeSelected) {
      loadData();
    }
  }, [allocationType, pageState?.pageSize, pageState?.page, isAccountDeSelected, isClearPerformed]);

  return (
    <div style={Container}>
      <div className="assignBankStatementBar">
        <span style={{ color: "#FF5A01", fontSize: "20px" }}>
          Bank Statement Transactions
        </span>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          style={{
            border: "1px solid black",
            borderRadius: "9px",
          }}
          aria-label="disabled tabs example"
        >
          <Tab
            style={{
              borderRight: "1px solid black",
              backgroundColor: tabValue === 0 ? "#FF5A01" : "",
              color: tabValue === 0 ? "black" : "",
            }}
            label="Allocated"
          />

          <Tab
            label="Open"
            style={{
              backgroundColor: tabValue === 1 ? "#FF5A01" : "",
              color: tabValue === 1 ? "black" : "",
              paddingLeft: "2px",
            }}
          />
        </Tabs>
      </div>
      <Grid container xs={12} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>

        <Grid
          container
          className="bankStatementSearchContainer"
          xs={9.8}
          gap={"5px"}
        >
          <Grid
            item
            xs={2.5}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Bank Name</FormLabel>
            <CommonSelect
              placeholder="Select Bank Name"
              value={searchFieldsPrams?.Bank_Name ?? ""}
              handleChange={(event, value, reason) =>
                handleBankNameDropdownChange(
                  "Bank_Name",
                  value,
                  reason,
                  event
                )
              }
              options={banksList || []}
            />
          </Grid>
          <Grid
            item
            xs={2.5}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Account No</FormLabel>
            <CommonSelect
              placeholder="Select Account No"
              value={searchFieldsPrams?.Receiving_Bank_Account ?? ""}
              handleChange={(event, value, reason) => handleBankTnxSelectAccountDropdown("Receiving_Bank_Account", value === "" ? null : value, reason)}
              options={selectedBankAccount || []}
            />
          </Grid>
          <Grid
            item
            xs={2.5}
            display={"flex"}
            flexDirection={"column"}
            gap={"4px"}
          >
            <FormLabel>Transaction Id</FormLabel>
            <TextField
              value={searchFieldsPrams?.Bank_Transaction_Id ?? ""}
              onChange={(e) =>
                handleBankTransactionIdchange(
                  "Bank_Transaction_Id",
                  e.target.value
                )
              }
              sx={{ width: "auto" }}
              size="small"
            />
          </Grid>
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            gap={"2px"}
          >
            <FormLabel>Transaction Period</FormLabel>
            <CustomDateRangePicker
              startDate={fromDateValue}
              setStartDate={setFromDateValue}
              endDate={toDateValue}
              setEndDate={setToDateValue}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item ml={"15px"} display={"flex"}
            flexDirection={"column"} gap={"20px"}>
            <FormLabel></FormLabel>
            <CommonButton
              text="Search"
              className="bankStatementBtn"
              handleClick={handleSearchBankTransactionList}
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
          <Grid item gap={"20px"} display={"flex"}
            flexDirection={"column"}>
            <FormLabel></FormLabel>
            <CommonButton
              text="Clear"
              handleClick={handleClearSearchList}
              className="bankStatementBtn"
              hoverColor="#EE3F00"
              disabled={pageState?.isLoading || isFilterBtnDisable()}
            />
          </Grid>
        </Grid>

        <Grid
          container
          xs={2.2}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-end"}
          alignSelf={"flex-end"}
        >
          <div style={{display:"flex",gap:"10px"}}>
            {bankTransactionPermisson?.["Allocation"] === "Y" && (
              <CommonButton
                sx={{
                  background: isButtonDisabled ? "#CCCCCC" : "#FF5A01",
                  color: "white",
                  borderRadius: "20px",
                  padding: "5px 20px",
                  width: "91px",
                }}
                text="Assign"
                handleClick={() => handleAssignButtonClick()}
                disabled={isButtonDisabled}
                hoverColor="#FF5A01"
              />
            )}
            {bankTransactionPermisson?.["Creation TXN"] === "Y" && (
              <CommonButton
                text="Add"
                handleClick={() => handleAddTxnButton()}
                className="bankTransactionAddBtn"
                hoverColor="#EE3F00"
              />
            )}
          </div>
        </Grid>

      </Grid>
      <div
        style={{
          height: 'calc(100vh - 293px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          rowCount={pageState?.total}
          columns={columns}
          checkboxSelection
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          startIcon={<AddIcon />}
          getRowId={(row) => row?.id}
          pagination
          paginationMode="server"
          onSelectionModelChange={handleSelectionModelChange}
          selectionModel={selectionModel}
          disableSelectionOnClick
          page={pageState.page - 1}
          pageSize={pageState?.pageSize ?? 25}
          onPageSizeChange={(newPageSize) => {
            return setPageState((old) => {
              setPaginationCount(getPaginationCount(pageState?.total, newPageSize))
              return { ...old, pageSize: newPageSize, page: 1 };
            });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='File_Name']": {
              minWidth: toggle?.isOpen ? "173.137px !important" : "202px !important"
            }
          }}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Transactions
              </Stack>
            ),
          }}
        />
        <Stack
          style={{
            position: "absolute",
            bottom: "8px",
          }}
        >
          <CommonPaginationContainer
            count={paginationCount + 1}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Stack>
      </div>
      <Dialog
        open={isOpenAddBankTxnDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{ style: dialogStyle }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default" }}>
          {"Add Bank Transaction"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon className="CloseIconStyle" />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <Grid display={"flex"} flexDirection={"column"}>
            <Card elevation={5} className="EditPageContainer">
              <Grid xs={12} gap={1} container className="EditpageContainerItems">
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"4px"}
                >
                  <FormLabel>
                    Select Account No
                    {!viewMode && <span style={{ color: "red" }}>*</span>}
                  </FormLabel>
                  <CommonSelect
                    name="accountNo"
                    placeholder="Select Account No"
                    value={selectedOption}
                    handleChange={(event, value, reason) =>
                      handleBankAccountDropdownChange(value, reason)
                    }
                    options={
                      accountsList?.map((option) =>
                        option?.account_number?.trim()
                      ) || []
                    }
                    disabled={viewMode}
                  />
                  {validationStatus.map(
                    (item) =>
                      item?.account_number && (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {item?.account_number}
                        </Typography>
                      )
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Bank Name</FormLabel>
                  <TextField
                    value={responseData?.bank_name ?? ""}
                    size="small"
                    disabled={viewMode}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.5}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Entity Name</FormLabel>
                  <TextField
                    value={responseData?.entity_name ?? ""}
                    size="small"
                    disabled={viewMode}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"5px"}
                >
                  <FormLabel>Currency</FormLabel>
                  <TextField
                    value={responseData?.currency ?? ""}
                    size="small"
                    disabled={viewMode}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignSelf={"end"}
                  gap={"5px"}
                >
                  {!editMode && !viewMode && (
                    <div className="FileUploadIcon">
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                        onClick={handleFileUploadDialog}
                      >
                        <span
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          Files: <AttachFileIcon />
                        </span>
                        {uploadedFiles[0]?.name}
                      </div>

                      <CommonFileUpload
                        isFileUploadDialogOpen={isFileUploadDialogOpen}
                        uploadedFiles={uploadedFiles}
                        setUploadedFiles={setUploadedFiles}
                        setIsFileUploadDialogOpen={setIsFileUploadDialogOpen}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <div elevation={6} className="EditPageContainerTransactions">
            <div className="EditPageContainerTransactionsList">
              <p style={{ color: "black" }}>Transactions</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <CommonButton
                  text="Add"
                  handleClick={handleAddRow}
                  icon={<AddIcon style={{ height: "18px" }} />}
                  hoverColor="#FF5A01"
                />
                <CommonButton
                  sx={{
                    backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                    color: selectAll ? "white" : "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "17px 10px",
                    borderRadius: "15px",
                    "&:hover": {
                      background: selectAll ? "#FF5A01" : "#CCCCCC",
                    },
                  }}
                  text="Delete"
                  handleClick={handleDeleteRow}
                  disabled={selectedRows.length === 0}
                  icon={<DeleteIcon style={{ height: "19px" }} />}
                />
              </div>
            </div>

            {isCreateNewTransaction && (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddBnkTransactionDetails
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  handleBlur={handleBlur}
                  handleCheckboxChange={handleCheckboxChange}
                  handleBrokerBranchDropdown={handleBrokerBranchDropdown}
                  viewMode={viewMode}
                  validationStatus={validationStatus}
                  dropdownValuesCurreny={dropdownValuesCurreny}
                  brokerBranches={brokerBranches}
                  handlePaymentReferenceOpenDialog={
                    handlePaymentReferenceOpenDialog
                  }
                  selectedRows={selectedRows}
                />
              </TableContainer>
            )}
          </div>
        </DialogContent>

        <DialogActions className="resetSubmit">
          {isCreateNewTransaction && (
            <>
              <CommonButton
                className="addBankTransactionReset"
                text="Reset"
                handleClick={() => setOpenResetDialog(true)}
                disabled={editMode}
              />
              <CommonButton
                className="addBankTransactionSubmit"
                text="Submit"
                handleClick={handleSubmit}
                hoverColor="#FF5A01"
                disabled={isSubmitting} // Disable the button when submitting
                style={{
                  backgroundColor: isSubmitting ? "#CCCCCC" : undefined, // Changed to red when submitting
                  color: isSubmitting ? "#666666" : undefined
                }}
              />
            </>
          )}
        </DialogActions>
      </Dialog>
      {
        isOpenTxnDialog && (
          <ViewAndEditBankTransaction
            isOpenTxnDialog={isOpenTxnDialog}
            setIsOpenTxnDialog={setIsOpenTxnDialog}
            txnMode={txnMode}
            txnData={currentRow}
            handleReloadAccountsData={handleReloadAccountsData}
            navigatedFrom="BANK_TRANSACTION"
          />
        )
      }

      <DialogBox
        title="Assign To"
        body={
          <FormControl style={{ width: "100%" }}>
            <label style={{ marginBottom: 10 }}>Assigning to </label>
            <CommonSelect
              name="assigning"
              value={selectedUser}
              handleChange={(event, value, reason) =>
                setSelectedUser(value, reason)
              }
              options={users?.map((option) => option?.user_name)}
            />
          </FormControl>
        }
        confirmText="Assign"
        isOpen={isDialogOpen}
        onCancel={handleCloseDialog}
        onConfirm={handleSubmitAssing}
        isDisableConfirm={isAssignToTxnApiProgress}
      />
      <Dialog open={openDialog} onClose={handleCloseDialogs}>
        <DialogTitle>Payment Reference</DialogTitle>
        <DialogContent sx={{ overflow: "hidden" }}>
          <TextareaAutosize
            minRows={5}
            value={dialogInputValue}
            onChange={handleDialogInputChange}
            className="PaymentReferenceDialogue"
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            text="Cancel"
            handleClick={handleCloseDialogs}
            sx={{
              backgroundColor: "#ffff",
              color: "#000000",
              border: "1px solid #BCBDBF",
            }}
          />
          <CommonButton
            text="Save"
            handleClick={handleSavePaymentReference}
            hoverColor="#FF5A01"
          />
        </DialogActions>
      </Dialog>

      <DialogBox
        title="Delete Confirmation"
        body="Are you sure you want to delete this item?"
        confirmText="Delete"
        isOpen={open}
        onCancel={handleDeleteCancelled}
        onConfirm={handleDeleteConfirmed}
        isDisableConfirm={isDeletebankTxnProgress}
      />

      <DialogBox
        title="Reset Confirmation"
        body="Are you sure you want to reset?"
        confirmText="Yes"
        cancelText="No"
        isOpen={openResetDialog}
        onCancel={() => setOpenResetDialog(false)}
        onConfirm={handleReset}
      />

      <DialogBox
        title="Warning !!"
        body="Transaction cannot be deleted as Cash items are associated with it."
        isOpen={openErrorDialog}
        onCancel={handleErrorDialogClose}
        showConfirmButton={false}
      />       
    </div >
  );
}
