import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BankExchangeRateAPI } from "../../Services/api";
import instance from "../../redux/api";
import { CommonButton } from "../common/commonButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableContainer,
} from "@mui/material";
import { ViewAndEditEntity } from "./ViewAndEditEntity";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AddBankExchange } from "./AddBankExchange";
import format from "date-fns/format";
import { useStyles } from "../bankTransactions/bankTranscationContainerStyles";
import { getCurrencyList } from "../../redux/slice/bankStatmentTransactionsSlice";
import { numberFormatter } from "../../utils/generalFunctions";

//styles

const AssignBankStatementBar = {
  display: "flex",
  justifyContent: "space-between",
  height: 32,
  fontWeight: "400",
  marginTop: 10,
};
const editEntityUpdate = {
  width: "90px",
  padding: "18px 0px",
  borderRadius: "20px",
  gap: "8px",
}

const bankTransactionAddBtn = {
  background: "#FF5A01",
  marginLeft: "5%",
  color: "white",
  borderRadius: "10px",
  padding: "5px 20px",
};

const ResetSubmit = {
  justifyContent: "flex-end",
  paddingTop: "0px",
  marginRight: "12px",
};
const addBankTransactionReset = {
  backgroundColor: "white",
  width: "90px",
  padding: "16px 0px",
  borderRadius: "20px",
  gap: "8px",
  color: "black",
  border: "1px solid black",
};

const EditPageContainerTransactions = {
  padding: "10px",
  cursor: "default",
  width: "100%",
  marginLeft: "-10px",
};
const EditPageContainerTransactionsList = {
  display: "flex",
  justifyContent: "end",
  width: "100%",
  alignItems: "center",
  marginBottom: "10px"
};

const CloseIconStyle = {
  backgroundColor: "#3B3F44",
  color: "#ffff",
  height: "16px",
  width: "16px",
  padding: "7px 8px",
  borderRadius: "32px",
};
const addBankTransactionSubmit = {
  backgroundColor: "#FF5A01",
  width: "90px",
  padding: "17px 0px",
  borderRadius: "13px",
  gap: "8px",
  color: "white",
};

export default function BankExchangeRate() {
  // Selectors
  const toggle = useSelector((state) => state.toggleSideMenu);
  const { userData } = useSelector((state) => state?.user);

  //Local state
  const [rows, setRows] = useState([{ month: "", currency_code: "", exchange_rate: "" }]);
  const [EntityDialog, setEntityDialog] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isCreated, setIsCreated] = useState(true);
  const [editId, setEditId] = useState("");
  const [validationStatus, setValidationStatus] = useState(
    Array(rows.length).fill(true)
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch Currency Details
    dispatch(getCurrencyList());
  }, [dispatch]);

  //For pagination
  const [, setPaginationCount] = useState(0);
  const [pageState, setPageState] = useState({
    isLoading: true,
    data: [],
    total: 0,
    page: 1,
    pageSize: 25,
  });

  // skip 0 means page 1
  const currentSkipNumber = () => {
    return pageState?.page === 1
      ? 0
      : pageState?.page === 0
        ? pageState?.page
        : pageState?.page - 1;
  };

  useEffect(() => {
    if (pageState?.total >= 5) {
      setPaginationCount(Math.floor(pageState.total / pageState?.pageSize));
    } else setPaginationCount(0);
  }, [pageState?.pageSize, pageState.total]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState?.pageSize, pageState?.page]);

  const loadData = async () => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const response = await instance.get(
        `${BankExchangeRateAPI}?skip=${currentSkipNumber()}&pageSize=${pageState?.pageSize
        }`
      );
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));

      if (response.status === 200) {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: response?.data ?? [],
        }));
        setPaginationCount(
          Math.floor(response?.data?.count / pageState?.pageSize)
        );
      } else {
        setPageState((old) => ({
          ...old,
          total: response?.data?.count,
          data: [],
        }));
        setPaginationCount(0);
      }
    } catch (err) {
      setPageState((old) => ({
        ...old,
        isLoading: false,
      }));
      setPaginationCount(0);
      console.error("err", err);
    }
  };

  const Container = {
    width: toggle?.isOpen ? "calc(100vw - 305px)" : "calc(100vw - 100px)",
    display: "flex",
    flexDirection: "column",
    gap: 8,
  };

  const handleDialogClose = () => {
    setEntityDialog(false);
    setEditMode(false)
    setViewMode(false)

  };

  const handleCheckboxChange = (index, isChecked) => {
    const updatedSelectedRows = [...selectedRows];
    updatedSelectedRows[index] = isChecked;

    // Check the number of selected rows
    const selectedRowCount = updatedSelectedRows.filter(Boolean).length;

    setSelectedRows(updatedSelectedRows);
    setSelectAll(selectedRowCount === rows.length);
  };

  const handleActionButton = (rowData, isEditMode) => {
    const emptyRows = Array.from({ length: rows.length }, () => ({ month: "", currency_code: "", exchange_rate: "" }));
    if (rowData && isEditMode) {
      // Pre-fill the empty row with data for editing
      setRows([rowData]);
      setEditId(rowData.id);
      setIsCreated(false);
    } else if (rowData && !isEditMode) {
      setRows([rowData]);
      setViewMode(true);
    } else {
      // If no rowDataForEdit is provided, it means we are in add mode
      setRows(emptyRows);
      setIsCreated(true);
      setEntityDialog(true);
    }
    setEntityDialog(true);
    if (rowData) {
      setIsCreated(false);
    } else {
      setIsCreated(true);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3, headerAlign: "center" },
    {
      field: "month",
      headerName: "Month",
      flex: 0.8,
      headerAlign: "center",
      valueFormatter: (params) => {
        // Check if params.value is a valid date
        const dateValue = new Date(params.value);
        if (!isNaN(dateValue.getTime())) {
          // If it's a valid date, format it
          return format(dateValue, "MMMM yyyy");
        } else {
          // If it's not a valid date, return null
          return "";
        }
      },
    },
    {
      field: "currency_code",
      headerName: "Currency Code",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "exchange_rate",
      headerName: "Exchange Rate",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => <>{numberFormatter(params?.row?.exchange_rate) ?? ""}</>,
      cellClassName: "amount-table-cell"
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.8,
      cellClassName: "actions",
      getActions: ({ row }) => {
        let actionsList = [];
        if (UserAuthorisation?.['View'] === "Y") {
          actionsList.push(
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="view"
              className="textPrimary"
              onClick={() => handleActionButton(row, false)}
              color="inherit"
            />
          );
        }

        return actionsList;
      },
    },
  ];
  const UserAuthorisation =
    userData?.user_permissions?.permissions_list['Admin Table Maintenance'];

  const handleInputChange = (index, colName, value) => {
    const updatedRows = [...rows];
    if (updatedRows[index]) {
      if (colName === "exchange_rate") {
        updatedRows[index][colName] = Number(value);
      } else {
        updatedRows[index][colName] = value;
      }
      setRows(updatedRows);
    } else {
      console.error("Row at index", index, "is undefined.");
    }
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = new Array(rows.length).fill(isChecked);

    setSelectedRows(updatedSelectedRows);
    setSelectAll(isChecked);
  };

  const handleAddRow = () => {
    setRows([...rows, { month: "", currency_code: "", exchange_rate: "" }]);
  };
  const handleDeleteRow = () => {
    // Ensure there is at least one row remaining
    if (rows.length <= 1 || selectAll) {
      // Throw an error or show a message indicating that at least one row should remain
      toast.error("At least One Row Should Remain!!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    const updatedRows = rows.filter((row, index) => !selectedRows[index]);
    setRows(updatedRows);

    // Clear selected rows and set Select All to false after deletion
    setSelectedRows([]);
    setSelectAll(false);
  };

  const validateRow = (index) => {
    const row = rows[index];
    const fieldValidations = {};

    // Perform your validations here
    if (!row || !row.month || !row.month.trim() === "") {
      fieldValidations.Month = "This field is required";
    }
    if (!row || !row.currency_code || !row.currency_code === "") {
      fieldValidations.currency_code = "This field is required";
    }
    if (!row || !row.exchange_rate || !row.exchange_rate) {
      fieldValidations.exchange_rate = "This field is required";
    }

    // Add more validations for other fields...
    setValidationStatus((prevStatus) => {
      const newStatus = [...prevStatus];
      newStatus[index] = fieldValidations;
      return newStatus;
    });

    return Object.keys(fieldValidations).length === 0;
  };

  const handleSubmit = async () => {
    // Validate each row before submission
    const areRowsValid = rows.every((_, index) => validateRow(index));

    if (!areRowsValid) {
      // If any row is invalid, stop the submission and display an error
      toast.error("Please fix validation errors before submitting.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    try {
      let response;
      if (editMode) {
        response = await instance.patch(
          BankExchangeRateAPI + editId + "/",
        );
        // clear preiously updated stored data
      } else {
        let updatedRows = [...rows];
        for (let i = 0; i < updatedRows?.length; i++) {
          updatedRows[i].created_by = userData?.user_name;
        }
        response = await instance.post(BankExchangeRateAPI, updatedRows);
      }

      if (response) {
        if (editMode) {
          toast.success("Updated successfully !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.success("Submission successful !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }

        // clean up
        cleanupTransactionData();
      }
    } catch (err) {
      toast.error("Submission failed. Please try again.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const cleanupTransactionData = () => {
    handleDialogClose();
    loadData();
  };

  const uploadFile = async (formData) => {
    try {
      const response = await instance.post(
        "documents/roe_file_upload/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );  
      if (response.status === 200) {

        toast.success("File uploaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(error?.data?.message || "File not uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    setIsButtonDisabled(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsButtonDisabled(true);

    const fileSizeLimit = 10485760;
  
    // Check if the file is an Excel file
    const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', "text/csv"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload a valid Excel file.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsButtonDisabled(false);
      return;
    }

    // Check if the file size exceeds the limit
    if (file.size > fileSizeLimit) {
      toast.error("File size should be less than or equal to 10 MB.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsButtonDisabled(false);
      return;
    }

    // Check if the file name length exceeds 256 characters
    if (file.name.length > 256) {
      toast.error("File name should be less than 256 characters.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsButtonDisabled(false);
      return;
    }
  
    // Prepare FormData
    const formData = new FormData();
    formData.append("file", file);
  
    // You can add additional parameters here if needed
    // formData.append("paramName", paramValue);
  
    uploadFile(formData);
  };

  return (
    <div style={Container}>
      <div style={AssignBankStatementBar}>
        <span style={{ color: "#FF5A01", fontSize: "24px" }}>
          Bank Exchange Rate
        </span>
        <div style={{ display: "flex", marginRight: "10px" }}>
          {UserAuthorisation?.["Creation"] === "Y" && (
            <div className="add-upload-container" style={{display:"flex", gap:"10px"}}>
              <CommonButton
                text="Add"
                handleClick={() => handleActionButton()}
                sx={bankTransactionAddBtn}
                hoverColor="#FF5A01"
              />
              <Button
                color="inherit"
                variant="outlined"
                component="label"
                style={{ width: "150px", height:"33px" }}
                disabled={isButtonDisabled}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  multiple
                  name="file"
                  accept=".doc,.docx,.xlsx,.csv,.pdf"
                  onChange={handleFileChange}
                  onClick={(e) => (e.target.value = null)}
                  required
                  style={{ borderBottom: "none" }}
                />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          height: 'calc(100vh - 230px)',
          position: "relative",
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <DataGrid
          className={classes.pagination}
          loading={pageState?.isLoading}
          rows={pageState?.data ?? []}
          columns={columns}
          rowCount={pageState?.total}
          hideFooterPagination
          hideFooter
          keepNonExistentRowsSelected
          disableSelectionOnClick
          pagination
          paginationMode="server"
          getRowId={(row) => row?.id}
          components={{
            Toolbar: GridToolbarContainer, // Custom toolbar for filter reset button
            NoRowsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Bank Exchange Rate
              </Stack>
            ),
          }}
          sx={{
            "& .MuiDataGrid-columnHeader[data-field='actions']": {
              minWidth: toggle?.isOpen ? "260px !important" : "303px !important"
            }
          }}
        />
      </div>
      <Dialog
        open={EntityDialog}
        disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" sx={{ cursor: "default", paddingBottom: "0px" }}>
          {viewMode ? "View Bank Exchange" : editMode ? "Edit Bank Exchange" : "Add Bank Exchange"}
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            style={{ position: "absolute", right: 12, top: 12 }}
          >
            <CloseIcon style={CloseIconStyle} />
          </IconButton>
          <hr style={{ width: "100%" }} />
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: "hidden", padding: "0px 24px 5px 24px" }}
        >
          <div elevation={4} style={EditPageContainerTransactions}>
            {!editMode && !viewMode && (
              <div style={EditPageContainerTransactionsList}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <CommonButton
                    text="Add"
                    handleClick={handleAddRow}
                    icon={<AddIcon style={{ height: "18px" }} />}
                    hoverColor="#FF5A01"
                  />
                  <CommonButton
                    sx={{
                      backgroundColor: selectAll ? "#FF5A01" : "#CCCCCC",
                      color: selectAll ? "white" : "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "17px 10px",
                      borderRadius: "15px",
                      "&:hover": {
                        background: selectAll ? "#FF5A01" : "#CCCCCC",
                      },
                    }}
                    text="Delete"
                    handleClick={handleDeleteRow}
                    disabled={selectedRows.length === 0}
                    icon={<DeleteIcon style={{ height: "19px" }} />}
                  />
                </div>
              </div>
            )}
            {isCreated ? (
              <TableContainer style={{ maxHeight: "300px" }}>
                <AddBankExchange
                  selectAll={selectAll}
                  handleInputChange={handleInputChange}
                  handleSelectAll={handleSelectAll}
                  rows={rows}
                  handleCheckboxChange={handleCheckboxChange}
                  validationStatus={validationStatus}
                  selectedRows={selectedRows}
                />
              </TableContainer>
            ) : (
              <>
                {rows?.map((row, index) => (
                  <Grid display={"flex"} flexDirection={"column"}>
                    <ViewAndEditEntity
                      row={row}
                      index={index}
                      viewMode={viewMode}
                      validationStatus={validationStatus}
                      editMode={editMode}
                      handleInputChange={handleInputChange}
                    />
                  </Grid>
                ))}
              </>
            )}
          </div>
        </DialogContent>

        <DialogActions style={ResetSubmit}>
          {viewMode ?
            null :
            <>
              {isCreated &&
                <CommonButton
                  sx={addBankTransactionReset}
                  text="Reset"
                  disabled={editMode}
                />
              }
              {
                editMode && (
                  <CommonButton
                    sx={editEntityUpdate}
                    text='Update'
                    handleClick={handleSubmit}
                    hoverColor="#FF5A01"
                  />)
              }
              {!editMode && !viewMode && (
                <CommonButton
                  sx={addBankTransactionSubmit}
                  text='Submit'
                  handleClick={handleSubmit}
                  hoverColor="#FF5A01"
                />)
              }
            </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
